<template>
  <div>
    <div class="master-login">
      <el-row>
        <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16" style="padding-left: 0px; padding-right: 0px">
          <div class="login-left">
            <div class="login-left-heading">
              <div class="login-left-icon">
                <img :src="loginBgImage" />
              </div>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <div class="login-form">
               <div >
                <img :src="logo" />
              </div>
            <h1>Login to your Super Admin Account</h1>
            <el-form
              ref="loginForm"
              :model="loginForm"
              :rules="loginRules"
              auto-complete="on"
              label-position="left"
            >
              <el-form-item label="Username" prop="username">
                <el-input
                  v-model="loginForm.username"
                  placeholder="Username"
                  name="username"
                  type="text"
                  auto-complete="off"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="Password" prop="password">
                <el-input
                  v-model="loginForm.password"
                  :type="passwordType"
                  placeholder="Password"
                  name="password"
                  auto-complete="on"
                  @keyup.enter.native="handleLogin"
                ></el-input>
              </el-form-item>
              <br />
              <el-button
                :loading="loading"
                type="primary"
                style="width: 100%; margin-bottom: 30px"
                @click.native.prevent="handleLogin"
                ><span style="color:white">Sign in</span></el-button
              >
              <!-- {{getRoleSlug}} -->

            </el-form>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>



<script>
// import axios from "axios";
import { getDeviceInfo } from "@/api/login";
// import { getDeviceInfo, ipAddress } from "@/api/login";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";

import {
  isMobileOnly,
  isTablet,
  isBrowser,
  isSmartTV,
  isWearable,
  isConsole,
  isAndroid,
  isWinPhone,
  isIOS,
  osName,
  osVersion,
  browserName,
  fullBrowserVersion,
} from "mobile-device-detect";

import moment from "moment";

export default {
  name: "SuperAdminLogin",
  components: {},
  data() {
    return {
      logo: "",
      loginBgImage: null,
      loginForm: {
        username: "",
        password: "",
        role: "",
      },
      loginRules: {
        username: {
          required: true,
          message: "Please enter the correct user name",
          trigger: "blur",
        },
        password: {
          required: true,
          message: "The password is required",
          trigger: "blur",
        },
      },
      passwordType: "password",
      loading: false,

      timeNow: "",
      // ipAddress: "",
      deviceInfo: {},
      locationDetails: "",
    };
  },
     computed: {
    ...mapGetters(["getRoleSlug", "getSiteConfig"]),
  },
  mounted() {
    this.loadDynamicImgs();
  },
  created() {
    mapActions(["setRoleSlug"]),
     this.setRoleSlug('superadmin')
    // //////////////////////
    // Get device info
    // //////////////////////
    this.deviceInfo = getDeviceInfo(
      isMobileOnly,
      isTablet,
      isBrowser,
      isSmartTV,
      isWearable,
      isConsole,
      isAndroid,
      isWinPhone,
      isIOS,
      osName,
      osVersion,
      browserName,
      fullBrowserVersion
    );

    // //////////////////////
    // Geo location
    // //////////////////////
    this.timeNow = moment(new Date()).format("DD/MM/YYYY hh:mm A");

    ///////////////
    // IP Address
    // // /////////
    // ipAddress({})
    //   .then((response) => {
    //     // console.log(response.data)
    //     if (response.data.success === true) {
    //       this.ipAddress = response.data.data.ip_address;
    //     } else {
    //       this.ipAddress = "";
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  },
  methods: {
    ...mapActions(["setRoleSlug"]),

    handleLogin() {

      // console.log(this.loginForm);
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$store
            .dispatch("LoginByUsername", this.loginForm)
            .then(() => {
              this.$router.push({
                path: this.redirect || "/super-admin/dashboard",
              });
                this.setRoleSlug('superadmin')
            })
            .catch(() => {
              console.log("login error occurred");
               this.flashError('Invalid login credentials');
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    loadDynamicImgs() {
      import(`@/assets/img_${this.getSiteConfig.loginBg}`).then((module) => this.loginBgImage = module.default);
      import(`@/assets/img_${this.getSiteConfig.logo}`).then((module) => this.logo = module.default);
    }
  },
};
</script>
